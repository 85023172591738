export const alumni = [
  {
    "name": "Christine Baker",
    "img": "christine_baker.jpg",
    "company": "JP Morgan"
  },
  {
    "name": "Morganne Farinha",
    "img": "morganne_farinha.jpg",
    "company": "Oracle"
  },
  {
    "name": "Seanie Clark",
    "img": "seanie_clark.jpg",
    "company": "Citi"
  },
  {
    "name": "Stephanie Browder",
    "img": "stephanie_browder.jpg",
    "company": "UBS"
  },
  {
    "name": "Camden Wiseman",
    "img": "camden_wiseman.jpg",
    "company": "RBC"
  },
  {
    "name": "Devansh Lahar",
    "img": "devansh_lahar.jpg",
    "company": "Goldman Sachs"
  },
  {
    "name": "Sarah Ashkar",
    "img": "sarah_ashkar.jpg",
    "company": "Guggenheim Partners"
  },
  {
    "name": "Caroline Paul",
    "img": "caroline_paul.jpg",
    "company": "UBS"
  },
  {
    "name": "Andrea Candidato",
    "img": "andrea_candidato.jpg",
    "company": "Evercore"
  },
  {
    "name": "Alexander Steigerwald",
    "img": "alexander_steigerwald.jpg",
    "company": "UBS"
  },
  {
    "name": "Bella Cruz",
    "img": "bella_cruz.jpg",
    "company": "Morgan Stanley"
  },
  {
    "name": "Ananya Gupta",
    "img": "ananya_gupta.jpg",
    "company": "PwC"
  },
  {
    "name": "Winston Feng",
    "img": "winston_feng.png",
    "company": "Goldman Sachs, Point72, MassAve"
  },
  {
    "name": "Samantha Kaner",
    "img": "samantha_kaner.png",
    "company": "Guggenheim Partners"
  },
  {
    "name": "Hrishi Bopalkar",
    "img": "hrishi_bopalkar.png",
    "company": "Greenhill & Co. Restructuring"
  },
  {
    "name": "Stratton Papakirk",
    "img": "stratton_papakirk.png",
    "company": "Blackstone"
  },
  {
    "name": "Alex Alma",
    "img": "alex_alma.jpg",
    "company": "Centerview Partners"
  },
  {
    "name": "Sadie Ravnitzky",
    "img": "sadie_ravnitzky.png",
    "company": "Guggenheim Partners"
  },
  {
    "name": "Nick Hahn",
    "img": "nick_hahn.jpg",
    "company": "Guggenheim Partners"
  },
  {
    "name": "Dhanush Kota",
    "img": "dhanush_kota.jpg",
    "company": "BofA, Sumeru Equity Partners"
  },
  {
    "name": "Rohan Gharekhan",
    "img": "rohan_garekhan.jpg",
    "company": "Goldman Sachs"
  },
  {
    "name": "Reed Hammer",
    "img": "reed_hammer.jpg",
    "company": "Goldman Sachs"
  },
  {
    "name": "Oliver Fisher",
    "img": "oliver_fisher.jpg",
    "company": "Goldman Sachs"
  },
  {
    "name": "Siyana Popova",
    "img": "siyana_popova.jpg",
    "company": "Morgan Stanley"
  },
  {
    "name": "Charlie Russell",
    "img": "charlie_russell.png",
    "company": "JP Morgan"
  },
  {
    "name": "Bradley Schoenfeld",
    "img": "bradley_schonfeld.jpg",
    "company": "Guggenheim Partners"
  },
  {
    "name": "Rachel Marsh",
    "img": "rachel_marsh.jpg",
    "company": "JP Morgan"
  },
  {
    "name": "Dhaval Patel",
    "img": "dhaval_patel.jpg",
    "company": "BlueCrest Capital"
  },
  {
    "name": "Bhoomika Jain",
    "img": "bhoomika_jain.png",
    "company": "BofA, Sumeru Equity Partners"
  },
  {
    "name": "Yoav Grainer",
    "img": "yoav_grainer.png",
    "company": "Barclays"
  },
  {
    "name": "Jonathan Glinton",
    "img": "jonathan_glinton.jpg",
    "company": "Bank of America"
  },
  {
    "name": "Matias Zorrilla",
    "img": "matias_zorrilla.jpg",
    "company": "Bank of America"
  },
  {
    "name": "Evan Byers",
    "img": "evan_byers.jpg",
    "company": "Houlihan Lokey"
  },
  {
    "name": "Darius Tam",
    "img": "darius_tam.jpg",
    "company": "Raymond James Restructuring"
  },
  {
    "name": "Matthew Tan",
    "img": "matthew_tan.png",
    "company": "EY-Parthenon"
  },
  {
    "name": "Ethan Rozin",
    "img": "ethan_rozin.jpg",
    "company": "Truist"
  },
  {
    "name": "Connor Novak",
    "img": "connor_novak.jpg",
    "company": "Barclays"
  },
  {
    "name": "Nicole Warszawski",
    "img": "nicole_warszawski.jpg",
    "company": "Wells Fargo"
  }
]

export const members = {
  "leadership": [
    {
      "name": "Angelina Gart",
      "position": "President",
      "desc": "Angelina Gart is a junior from New York City majoring in Applied Economics and Management with a concentration in Finance and a minor in Spanish. On campus, she is President of MergerSight Group and co-teaches their M&A Education Program. She is also a member of Cornell Financial Analysts. This past summer, she worked at Oak Hill Capital as a Private Equity Summer Analyst on their Industrials team and completed the Girls Who Invest Summer Intensive Program. Next summer, Angelina will be working at J.P. Morgan as an Investment Banking Summer Analyst. Angelina is passionate about languages and enjoys fine dining at new places. She can be reached at ag995@cornell.edu.",
      "pic": "angelina_gart.jpg"
    },
    {
      "name": "Andrew Chen",
      "position": "Executive Vice President",
      "desc": "Andrew Chen is a junior from Vancouver, Canada, double majoring in AEM and Information Science with minors in Computer Science and French. Next summer, he will be working at Dodge and Cox as an Investment Associate Intern. On campus, he is also involved with Cornell Financial Analysts and Cornell Data Science. In his free time, Andrew enjoys following FC Barcelona and the Vancouver Canucks, reading about history and politics, swimming, and listening to music. Andrew can be reached at ac2463@cornell.edu.",
      "pic": "andrew_chen.jpg"
    },
    {
      "name": "Pinak Chitnis",
      "position": "VP of Investing Education",
      "desc": "Pinak Chitnis is a junior from Cleveland, OH majoring in Applied Economics and Management with a concentration in Finance. On campus, he is also involved in Cornell Financial Analysts. This past summer, he worked at Sora Investors as an investment analyst in long/short equity. Next summer, Pinak will be working at Citadel as an investment analyst in long/short equity. In his free time, he enjoys hanging out with friends, watching sports, and trying new restaurants. He can be reached at puc4@cornell.edu.",
      "pic": "pinak_c.jpg"
    },
    {
      "name": "Pranav Dendukuri",
      "position": "VP of Investing Education",
      "desc": "Pranav Dendukuri is a junior from Long Island, NY, majoring in Applied Economics and Management with a concentration in Finance. On campus, he is also a part of Cornell Financial Analysts, Cornell Undergraduate Asia Business Society, and Cornell Alternative Investments. This past summer he worked at a hedge fund, focusing on consumer healthcare and has previous experiences as an investment banking intern at Freyeur & Trogue. Next summer, Pranav will be joining Point72 as an  Summer Academy Analyst. Outside of school, Pranav is an avid movie watcher, enjoys playing sports with his friends, and loves TikTok. He can be reached at pvd6@cornell.edu.",
      "pic": "pranav_d.jpg"
    },
    {
      "name": "Patrick Fang",
      "position": "VP of Investment Fund",
      "desc": "Patrick Fang is a sophomore from Westchester, NY, majoring in Applied Economics and Management with an intended concentration in Finance and a minor in data science. On campus, Patrick is also a member of Cornell Financial Analysts. This past summer, Patrick worked as an investment analyst at a small-cap hedge fund. In his free time, Patrick enjoys spending time with friends, playing volleyball and basketball, listening to music, and street photography. Patrick can be reached at pbf37@cornell.edu.",
      "pic": "patrick_fang.jpg"
    },
    {
      "name": "Jason Orenstein",
      "position": "VP of Recruitment",
      "desc": "Jason Orenstein is a sophomore from Albany, New York studying Hotel Administration with a concentration in finance and a minor in Real Estate. On campus, he is also a part of Cornell GameChangers, HEC, and CREC. This past summer, he worked as an intern at Trustage and First New York Federal Credit Union in private wealth management and financial planning. Jason is passionate about sports and enjoys eating around Ithaca. He can be reached at jmo235@cornell.edu or 518-709-3942.",
      "pic": "jason_orenstein.jpg"
    },
    {
      "name": "Matthew Devonish",
      "position": "VP of Recruitment",
      "desc": "Matthew Devonish is a sophomore from Brooklyn, New York, majoring in Applied Economics and Management with an intended concentration in finance. On campus, he is also a member of Cornell Financial Analysts. This past summer, Matthew worked as a private equity intern at Strategic Value Partners, focusing on distressed investment opportunities. In his free time, Matthew enjoys working out, following the NBA, and playing poker. He can be reached at med328@cornell.edu.",
      "pic": "matt_devonish.jpg"
    },
    {
      "name": "Raeyaan Bengali",
      "desc": "Raeyaan Bengali is a sophomore from Karachi, Pakistan, but he grew up in Dubai. He is majoring in economics and information science with a concentration in data science. On campus Raeyaan is also involved with Cornell Financial Analysts. This past summer he worked as a Private Equity analyst at Everstone Capital in Singapore. He can be reached at rrb245@cornell.edu",
      "position": "VP of Internal Affairs",
      "pic": "raeyaan_bengali.jpg"
    },
    {
      "name": "Sahil Voona",
      "position": "Treasurer",
      "desc": "Sahil Voona is a Sophomore from New Jersey majoring in Applied Economics and Management. On campus, he is the Fintech Sector lead for Diversity on the Street and is actively involved with Cornell Investment Banking Club, South Asian Business Association, and Cornell Fintech Club. This past summer, he worked as a Summer Analyst at Cornell’s Investment Office. Sahil is passionate about community service, enjoys playing basketball and soccer, and loves watching movies and his favorite football team, the New York Giants.",
      "pic": "sahil_voona.jpg"
    },
    {
      "name": "Alex Noviello",
      "position": "VP of Technology",
      "desc": "Alex Noviello is a sophomore from New Jersey, majoring in Computer Science & Math. On campus, Alex is involved with a variety of entrepreneurship-related activities, including building his own music education tech startup, Subito. Subito is backed by Life Changing Labs, Keel, 1517 Fund, and Emergent Ventures. Alex is also a TA for Cornell's undergrad machine learning class and does research on reasoning & neuro-inspired AI in the Sun lab. This coming summer, Alex will be doing data & ML infrastructure engineering for a large Fintech trading company in NYC. Alex enjoys playing golf poorly, watching good golf, scuba diving, skiing, traveling, and is very passionate about wildlife conservation! Alex can be reached at abn52@cornell.edu.",
      "pic": "alex_noviello.jpg"
    },
    {
      "name": "Andrew Noviello",
      "position": "VP of Technology",
      "desc": "Andrew Noviello is a sophomore from Bridgewater, New Jersey majoring in Computer Science and Mathematics. On campus, he is a member of various Cornell entrepreneurship groups and the Cornell M&A club. This past summer, Andrew interned in software development at Samsung; next summer he will be working as a software engineer in the Cloud Ecosystem group at Microsoft. In his free time, Andrew is also the co-founder of music education technology startup Subito, backed by Emergent Ventures and 1517 Fund. Alongside finance and technology, Andrew loves to ski, travel, and play the cello and is deeply passionate about environmental conservation. He can be reached at acn57@cornell.edu.",
      "pic": "andrew_noviello.jpg"
    },
    {
      "name": "Elsa Li",
      "position": "Alumni Chair",
      "desc": "Elsa Li is a sophomore from Northern Virginia majoring in Hotel Administration. On campus, she is involved with Cornell Financial Analysts, Cornell Undergraduate Asia Business Society, Cornell Real Estate Club, and Shadows Dance Troupe. This past summer, she worked as a Private Equity Summer Analyst at ADV Partners and a Capital Markets & Investments Summer Intern at Colliers in Shanghai. Next summer, she will be working as a Real Estate Investment Banking Summer Analyst at Morgan Stanley. In her free time, Elsa enjoys dancing, watching soccer and hockey, eating acai bowls, and exploring new cities. Elsa can be reached at eml245@cornell.edu.",
      "pic": "elsa_li.jpg"
    },
    {
      "name": "Ariane Chevrier",
      "position": "DEI Chair",
      "desc": "Ariane Chevrier is a sophomore from New York, NY, majoring in Applied Economics and Management within the S.C. Johnson College of Business. On campus, she is Vice President of Finance of Cornell Financial Analysts and a teaching assistant for AEM 2200 Business Management & Organization. This past summer, Ariane worked as a Business and Operations Analyst at Le Printemps, one of France’s most storied luxury department store chains. In her free time, Ariane enjoys watching Formula 1, binging TV shows, and playing soccer. Ariane can be reached at afc74@cornell.edu.",
      "pic": "ariane_chevrier.jpg"
    },
    {
      "name": "Sadie Burke",
      "position": "Social Chair",
      "desc": "Sadie Burke is a sophomore from Denver, Colorado majoring in Hotel Administration. On campus, she is the Recruitment Chair of Cornell Alternative Investments. She is also a member of Cornell Financial Analysts and TAMID Group. This past summer, she worked as a Private Equity Summer Analyst at Copley Equity Partners. Next summer, she will be working at Morgan Stanley as an Investment Banking Summer Analyst. Sadie loves trying new restaurants and watching Suits. She can be reached at srb339@cornell.edu.",
      "pic": "sadie_burke.jpg"
    }
  ],
  "members": [
    {
      "name": "Yudai Higuchi",
      "desc": "Yudai Higuchi is a senior from Yokohama, Japan, majoring in Hotel Administration with a minor in Real Estate. On campus, he serves as President Emeritus of the Cornell Real Estate Club and is also involved with the Cornell Financial Analysts and The Wardrobe. Previously, he interned with Blackstone's Real Estate Acquisitions team and TPG's Real Estate Private Equity team. Upon graduation, he will join PJT Partners in the Park Hill Real Estate Group. In his free time, he enjoys watching baseball, playing music, and cooking. Yudai can be reached at yh459@cornell.edu.",
      "grade": "Senior",
      "pic": "yudai_higuchi.jpg"
    },
    {
      "name": "Cameron Meyer",
      "desc": "Cameron Meyer is a junior from Alamo, CA double majoring in economics and biology & society. Previously, Cameron worked as an investment banking summer analyst for Morgan Stanley. In his free time, Cameron enjoys golfing and playing tennis.",
      "grade": "Junior",
      "pic": "cameron_meyer.jpg"
    },
    {
      "name": "Kevin Huang",
      "desc": "Kevin is a Junior from Long Island, NY in Dyson, concentrating in Finance, and minoring in Math and Data Science. On campus, he is also involved in Cornell Alpha Fund, Phi Gamma Nu, and Johnson Private Equity Club. This summer, he worked at Crestview Partners in NYC as a Private Equity Summer Analyst. Next summer, he will be joining Morgan Stanley as an Investment Banking Summer Analyst. Kevin is passionate about education equity, and enjoys competitive powerlifting, traveling, basketball, and picking up new hobbies. Kevin can be reached at kh639@cornell.edu.",
      "pic": "kevin_huang.jpg",
      "grade": "Junior"
    },
    {
      "name": "Luca Kalischer-Stork",
      "desc": "Luca Kalischer-Stork is a junior from Los Angeles majoring in Applied Economics and Management with a concentration in Finance. On campus, he is the executive vice president of banking at Cornell and a member of Cornell Financial Analysts. This past summer, he worked at a startup focused on adapting the factoring industry to blockchain. Luca is passionate about MMA, traveling, film and enjoys exploring new restaurants. He can be reached at lak257@cornell.edu.",
      "grade": "Junior",
      "pic": "luca_stork.jpg"
    },
    {
      "name": "Joshua Dong",
      "desc": "Joshua Dong is a junior from Long Island, NY, in Dyson concentrating in Finance with minors in Real Estate and Fungal Biology. On campus, Josh is also an associate at CREC Consulting and is involved with the real estate mentorship program. Last summer, Josh interned at Skybridge Capital pitching alternative investment ideas and analyzing hedge funds strategies. In his free time, Josh enjoys vlogging, non-profit work, poker, and reading.",
      "pic": "josh_dong.jpg",
      "grade": "Junior"
    },
    {
      "name": "Jake Friedman",
      "desc": "Jake is a junior from Westchester, NY majoring in Industrial and Labor Relations with a minor in Sustainable Business and Economic Policy. On campus he is a part of the Mutual Investment Club, MergerSight, and Cornell Technology Consulting. This past summer, he worked in New York Life’s field productivity division. He is also an incoming Investment Banking Summer Analyst for Mizuho. In his free time he enjoys skiing, listening to music, and watching the New York Jets. Jake can be reached at jlf342@cornell.edu.",
      "grade": "Junior",
      "pic": "jake_friedman.jpg"
    },
    {
      "name": "Georgia Kelly",
      "desc": "Georgia Kelly is a Junior from Dublin, Ireland majoring in Economics. On campus, she is a varsity athlete on the Field Hockey team and is a member of Cornell Financial Analysts. Last summer she worked in Morgan Stanley's investment banking division where she will be returning next summer. She enjoys travelling and spending time with friends. She can be reached at gjk72@cornell.edu.",
      "pic": "georgia_kelly.jpg",
      "grade": "Junior"
    },
    {
      "name": "Lakshman Sibal",
      "desc": "Lakshman is a sophomore from Edgemont, New York majoring in Industrial & Labor Relations with minors in Biometry & Statistics and Business. On campus, he is a Research Assistant for an AI-based asset pricing and portfolio optimization project in the Digital Economy & Financial Technology Lab in the SC Johnson School of Business. He is also part of the Cornell Emerging Markets Society. This past summer, he worked at Briarcliffe Credit Partners as a Private Credit Summer Analyst. Lakshman enjoys watching sports and exercising in his free time. He can be reached at lhs87@cornell.edu.",
      "pic": "lakshman_sibal.jpg",
      "grade": "Junior"
    },
    {
      "name": "William Najarian",
      "desc": "William Najarian is a junior from New York, NY, majoring in Applied Economics and Management. On campus, he is in the Cornell Investment Banking Club as well as the Phi Gamma Nu Business Fraternity. Last summer, he interned as an analyst at a Venture Capital firm. In his free time, William enjoys watching the Yankees, playing tennis, skiing, and watching F1 racing. He can be reached at wjn25@cornell.edu.",
      "pic": "william_najarian.jpg",
      "grade": "Junior"
    },
    {
      "name": "Otto Tiemann",
      "desc": "Otto Tiemann is a junior from Frankfurt, Germany majoring in Applied Economics & Management and Environment & Sustainability. On Campus he is involved with the Cornell Rugby Football Club, International Students Union and Cornell Horological Society. This past he interned in Real Estate Private Equity and will be working at Nomura next summer as an Investment Banking Summer Analyst. Otto is passionate about athletics and Alpha Fund.",
      "pic": "otto_tiemann.jpg",
      "grade": "Junior"
    },
    {
      "name": "Adam Dickstein",
      "desc": "Adam is a junior from New York City majoring in Economics within the College of Arts and Sciences. On campus, Adam is apart of the Cornell Investment Banking Club (CIBC) and the TAMID Group. This past summer, he interned at SkyBridge Capital, an alternative asset manager. Next summer, Adam will be working at Oppenheimer & Co. as an Investment Banking Summer Analyst. In his free time, Adam enjoys playing sports and hanging out with friends. He can be reached at asd237@cornell.edu.",
      "pic": "adam_dickstein.jpg",
      "grade": "Junior"
    },
    {
      "name": "Arin Atluri",
      "desc": "Arin is a junior from Westchester, NY majoring in Applied Economics and Management with concentrations in Finance and Business Analytics. On campus, he is also a member of the Cornell Club Tennis team and a teaching assistant for AEM 2600. Previously, he has worked as a corporate finance intern at L'Oréal USA, and as a venture capital intern for a MedTech startup called IrisMed. Outside of school, Arin loves watching the ATP Tour and the NBA, and also enjoys attending Auto Shows. Arin can be reached at aa2376@cornell.edu.",
      "pic": "arin_atluri.jpg",
      "grade": "Junior"
    },
    {
      "name": "Alex Siegel",
      "desc": "Alex Siegel is a sophomore from Long Island, NY, majoring in Public Policy with minors in Data Science and Business. On campus, he is also involved with Cornell Financial Analysts and Men's Club Lacrosse. This past summer, he interned for an equity long/short multi-manager hedge fund. In his free time, Alex enjoys golfing, playing poker, and watching the NFL. Alex can be reached at acs338@cornell.edu.",
      "pic": "alex_siegel.jpg",
      "grade": "Sophomore"
    },
    {
      "name": "Nicolas Thollot",
      "desc": "Nicolas is a sophomore from New York City studying Applied Economics and Management, with an intended concentration in Business Analytics and minor in Data Science. He is currently working for a family office as an Investment Researcher and Analyst and is also leading a rising student organization focused on quantitative trading. In his free time, Nicolas enjoys playing soccer, golfing, and hanging out with friends.",
      "pic": "nico_thollot.jpg",
      "grade": "Sophomore"
    },
    {
      "name": "Aryan Khattar",
      "desc": "Aryan is a sophomore student from Singapore who intends to major in Computer Science and Economics with a minor in Government in the college of Arts and Sciences. Outside of Alpha Fund, his other on-campus involvements include Cornell Financial Analysts and Cornell Alternative Investments. In his free time, Aryan enjoys golf, poker and hanging out with his friends. ",
      "pic": "aryan_khattar.jpg",
      "grade": "Sophomore"
    },
    {
      "name": "Aiden Rourke",
      "desc": "Aiden is a sophomore from Westport, CT, majoring in Industrial and Labor Relations with minors in business and history. On campus he is involved with Cornell Financial Analysts and the Cornell Alexander Hamilton Society. This past summer, Aiden interned at a private equity fund based in Boston. Aiden is passionate about history, loves watching the UFC, and enjoys snowboarding. Aiden can be reached at ajr327@cornell.edu.",
      "grade": "Sophomore",
      "pic": "aiden_rourke.jpg"
    },
    {
      "name": "Vatsal Kalola",
      "desc": "Vatsal Kalola is a sophomore from Long Island majoring in Applied Economics and Management with a concentration in Finance and an intended minor in data science. On campus, he is also a member of Cornell Equity Research and Cornell Global Business Club. This past summer he worked in both private wealth management and venture capital. Vatsal enjoys sports and the outdoors. He can be reached at vk366@cornell.edu.",
      "grade": "Sophomore",
      "pic": "vatsal_kalola.jpg"
    },
    {
      "name": "RJ Druker",
      "desc": "RJ Druker is a sophomore from New York City studying Economics and pursuing minors in Business and Law & Society. Aside from Alpha Fund, he is a member of TAMID at Cornell’s Investment Fund program. This past summer, he studied at the London School of Economics and interned under the CFO of ViroCell Biologics in London. RJ enjoys running and cooking dishes from Thai and French cuisines. He can be reached at rjd295@cornell.edu.",
      "grade": "Sophomore",
      "pic": "rj_druker.jpg"
    },
    {
      "name": "Dev Shukla",
      "desc": "Dev Shukla is a sophomore from New Delhi, India majoring in Applied Economics and Management & Information Science. On campus, he is also part of the Cornell Investment Banking Club, South Asian Business Association and Cornell Rocketry Team. This past summer, he worked as a private equity intern at Convergent Finance and was staffed on PIPEs and buyout deals. He was a professional cricket player for 7+ years and now enjoys boxing, paintbal, poker and lifting.",
      "grade": "Sophomore",
      "pic": "dev_shukla.jpg"
    },
    {
      "name": "Beatrice Glassner",
      "desc": "Beatrice Glassner is a sophomore from New York City, majoring in Applied Economics and Management with a concentration in Finance. On campus, she is also involved with Cornell Financial Analysts, the Cornell Tradition fellowship, and the Brazilian Student Association. This past summer, she worked at Tungsten Partners as an analyst. She loves languages, playing the classical piano, and working out with her friends.",
      "grade": "Sophomore",
      "pic": "beatrice_glassner.jpg"
    },
    {
      "name": "Eric Sun",
      "desc": "Eric Sun is a freshman from Boston majoring in Economics and Statistics. On campus, Eric is involved with Cornell CSA and intramural soccer. This past summer, he worked at Invenomic Capital Management as a Equity Summer Analyst. Eric is passionate about soccer, food, and skiing. He can be reached at es2273@cornell.edu",
      "grade": "Freshman",
      "pic": "eric_sun.jpg"
    },
    {
      "name": "Tarneet Singh",
      "desc": "Tarneet Singh is a freshman from Philadelphia majoring in Hotel Administration. He is passionate about his Philadelphia sports teams and enjoys playing golf and running. He can be reached at ts884@cornell.edu.",
      "grade": "Freshman",
      "pic": "tarneet_singh.jpg"
    },
    {
      "name": "Milla Fu",
      "desc": "Milla is a freshman from Hangzhou, China majoring in Global Development in CALS. On campus, she is also a member of Cornell Investments Club and Cornell Venture & Entrepreneurship Club. In her free time, she enjoys watching movies, exploring new restaurants, and snowboarding. She can be reached at yf423@cornell.edu.",
      "grade": "Freshman",
      "pic": "milla_fu.jpg"
    },
    {
      "name": "Noah Greenberg",
      "desc": "Noah Greenberg is a freshman from New York City, studying Mathematics and Economics in the school of Arts and Sciences. In his free time he enjoys playing golf and watching sports. Last summer he was a caddie. He can be reached at nwg24@cornell.edu.",
      "grade": "Freshman",
      "pic": "noah_greenberg.jpg"
    },
    {
      "name": "Arjun Khanna",
      "desc": "Arjun is a sophomore from New Jersey majoring in Economics and Information Science. On campus, he is also a member of Phi Gamma Nu. This past summer, he worked as a Fixed Income Analyst at Twin Lake Total Return Partners. Next summer, Arjun will be working at Morgan Stanley as a Wealth Management Sophomore Analyst. Arjun enjoys to play tennis and listen to country music. He can be reached at ak2435@cornell.edu.",
      "grade": "Sophomore",
      "pic": "arjun_khanna.jpg"
    },
    {
      "name": "Spencer Friedman",
      "desc": "Spencer Friedman is a freshman from Marlboro, New Jersey. He is majoring in Hotel Administration with a concentration in Real Estate and Finance. On campus he is involved with the Hotel Student Mentorship Program. Last year, he participated in the Morgan Stanley Jumpstart in Finance Program. Outside of school, Spencer enjoys traveling, and has visited all 50 U.S states. As a lifelong baseball player and fan, he has travelled to all 30 MLB stadiums. Spencer can be contacted at smf283@cornell.edu.",
      "grade": "Freshman",
      "pic": "spencer_friedman.jpg"
    },
    {
      "name": "Jenny Chen",
      "desc": "Jenny Chen is a freshman from the greater Washington, D.C. area majoring in Biology & Society and minoring in Business. On campus, she is also an analyst for Cornell Venture Capital and a member of the Business Sub Team for Cornell Rocketry. Jenny is passionate about the biotech industry and scientific research. In her free time, Jenny loves to listen to Radiohead and review movies on Letterboxd. She can be reached at jzc29@cornell.edu.",
      "grade": "Freshman",
      "pic": "jenny_chen.jpg"
    },
    {
      "name": "Haiar Isliamov",
      "desc": "Haiar Isliamov is a freshman from Ukraine majoring in Applied Economics and Management. On campus he is involved in Cornell Poker Club, Cayuga Capital and Ukrainian Club. Haiar is passionate about history and enjoys playing poker and chess. He can be reached at hi64@cornell.edu.",
      "grade": "Freshman",
      "pic": "haiar_isliamov.jpg"
    },
    {
      "name": "Ava Sobel",
      "desc": "Ava Sobel is a freshman from Long Island studying Industrial Labor Relations. On campus, she is recruitment chair for Cornell Finance Club. During her free time, Ava enjoys cooking new dishes and hiking outdoors. She can be reached at aps297@cornell.edu.",
      "grade": "Freshman",
      "pic": "ava_sobel.jpg"
    },
    {
      "name": "Henry Ji",
      "desc": "Henry Ji is a freshman from New York City majoring in Global and Public Health Sciences with a minor in business. On campus, he is a member of Cornell Emergency Medical Services, and also works at the Food Safety Lab and the Schaffer-Nishimura Lab. This past summer, he worked at Opti Capital Management on healthcare. Henry likes eating and talking. He can be reached at hj465@cornell.edu.",
      "grade": "Freshman",
      "pic": "henry_ji.jpg"
    },
    {
      "name": "Sofia Robb",
      "desc": "Sofia Robb is a freshman from New York City majoring in Applied Economics and Management. During her free time, Sofia enjoys cooking and playing volleyball. She can be reached at shr58@cornell.edu.",
      "grade": "Freshman",
      "pic": "sofia_robb.jpg"
    },
    {
      "name": "Ezana Kavuma",
      "desc": "Ezana Kavuma is a freshman from London, England, double majoring in Computer Science and Economics. On campus, he is an active member of Alpha Fund, where he has gained experience in finance accounting, mergers and acquisitions (M&A), leveraged buyouts (LBOs), financial analysis, and investment strategies through new member education and practical projects. Beyond Alpha Fund, Ezana co-founded Gympoint, a platform that helps individuals find and access gyms while traveling, where he leads efforts in marketing, business strategy, business valuation, and product innovation. He is particularly passionate about venture capital. Ezana can be reached at ekk62@cornell.edu.",
      "grade": "Freshman",
      "pic": "ezana_kavuma.jpg"
    }
  ]
}