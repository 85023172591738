import React from 'react';

const Recruitment = () => (
  <div className="p-4 md:p-24">
    <h2 className="text-3xl md:text-6xl font-bold mb-12 text-center">Recruitment Information</h2>
    <p className="mb-12 text-xl text-center">
      Interested in applying to Cornell Alpha Fund? Check out the links and recruitment timeline for the Spring 2025 Semester below. Applications for our Spring 2025 Analyst Class will open on <b>January 28th at 7 PM</b> and close on <b>February 4th at 7 PM</b>.
    </p>
    <div className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
      <a href="https://forms.gle/pAv4hcQcZViu26xn6" className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100">
        <svg className="mr-2 -ml-1 mt-0.5 w-5 h-5" fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" d="M.11 3.187A.5.5 0 0 1 .5 3h13a.5.5 0 0 1 .488.608l-.22.991a3.001 3.001 0 0 1-1.3 5.854l-.132.59A2.5 2.5 0 0 1 9.896 13H4.104a2.5 2.5 0 0 1-2.44-1.958L.012 3.608a.5.5 0 0 1 .098-.42Zm12.574 6.288a2 2 0 0 0 .866-3.899zM1.124 4l1.516 6.825A1.5 1.5 0 0 0 4.104 12h5.792a1.5 1.5 0 0 0 1.464-1.175L12.877 4H1.123Z" />
        </svg>
        Book a coffee chat
      </a>
      <a href="https://forms.gle/7roCtsyf53NV2EZy5" className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg border border-gray-300 bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-gray-100">
        <svg className="mr-2 -ml-1 w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
          <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
        </svg>
        Apply
      </a>
    </div>
    <h2 className="text-4xl font-bold mb-12 text-center">Recruitment Event Timeline</h2>
    <div className="px-4 md:px-48">
      <div className="relative wrap overflow-hidden p-10 h-full">
        <div className="border-2-2 absolute border-opacity-20 border-red-700 h-full border left-5 md:left-1/2"></div>
        {[
          { date: "January 22nd", title: "Coffee Chat Forms Open" },
          { date: "January 27th", title: "Breaking Into Business", details: "6-8pm Malott 228" },
          { date: "January 28th", title: "Application Opens!" },
          { date: "January 28th", title: "Info Session #1", details: "5-6:30pm Warren 173" },
          { date: "January 28th", title: "Breaking Into Banking", details: "7-9pm" },
          { date: "January 29th", title: "Finance Club Interview Prep", details: "6-9pm Statler 196" },
          { date: "January 31st", title: "Good Biz Bad Biz", details: "6-9pm Warren B75" },
          { date: "January 31st", title: "Resume Review", details: "6-9pm Warren B75" },
          { date: "February 3rd", title: "Info Session #2", details: "6:30-8pm MVR 155" },
          { date: "February 4th", title: "Applications Due at 7pm!" },
          { date: "February 7th", title: "Round 1 of Interviews (Invite Only)", details: "6-10pm" },
          { date: "February 9th", title: "Final Round Interviews (Invite Only)", details: "10am-2pm" },
        ].map((event, index) => (
          <div key={index} className={`mb-8 flex justify-between items-center w-full ${index % 2 === 0 ? 'md:flex-row-reverse' : 'md:flex-row'}`}>
            <div className="order-1 w-5/12 hidden md:block"></div>
            <div className="z-20 flex items-center order-1 bg-red-700 shadow-xl w-3 h-3 rounded-full absolute left-5 md:relative md:left-auto"></div>
            <div className={`order-1 w-full md:w-5/12 px-1 py-4 ml-8 md:ml-0 text-left ${index % 2 === 0 ? 'md:text-right' : 'md:text-left'}`}>
              <time className="mb-1 text-sm font-normal leading-none text-gray-400">{event.date}</time>
              <h3 className="text-lg font-semibold text-gray-900">{event.title}</h3>
              {event.details && <p className="text-base font-normal text-gray-500">{event.details}</p>}
            </div>
          </div>
        ))}
      </div>
      <p className="text-base font-normal text-gray-500 dark:text-gray-400 text-center"><i>*Locations will be continually updated on this page.</i></p>
    </div>
  </div>
)

export default Recruitment;